.bottom-overlay {
  @apply relative;
}

.bottom-overlay:before {
  @apply absolute left-0 right-0 bottom-0 w-full;

  content: '';
  background-image: linear-gradient(180deg, rgba(33, 39, 39, 0) 0%, rgba(33, 39, 39, 0.6) 40%, rgba(33, 39, 39, 0.9) 100%);
}

.bottom-overlay-lg:before {
  height: 32%;
}
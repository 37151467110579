.form-input,
.form-textarea,
.form-select,
.form-checkbox,
.form-radio {
  @apply block appearance-none py-3 px-5 text-gray-700 flex-grow rounded-sm;

  background: rgba(11,65,63,0.08);
}

.form-lg {
  @apply font-medium;
}

.form-highlight.form-input, .form-highlight.form-textarea {
  @apply border-b-2 border-teal-300 text-gray-800;
  @apply focus:border-teal-500 focus:outline-none;
}

.form-inverted {
  .form-input,
  .form-textarea,
  .form-select,
  .form-checkbox,
  .form-radio {
    @apply text-white placeholder-gray-100;
    background: rgba(255,255,255,0.4);
  }
}

.form-checkbox {
  @apply p-0 inline-block align-middle select-none flex-shrink-0 h-4 w-4 cursor-pointer;
}
.form-checkbox:focus {
  @apply ring-0 ring-white;
}

.form-checkbox:checked {
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 16 16" fill="126964" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"/></svg>');
}

.form-select {
  @apply outline-none cursor-pointer;

  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 11 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 8L0 0h11z' fill='%23A2B9B9' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
  background-position: right 1.25rem center;
  background-repeat: no-repeat;
  background-size: 11px 8px;
}

.form-select.form-lg {
  @apply px-5 py-3 w-full;
}
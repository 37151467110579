.button {
  @apply cursor-pointer inline-block py-2 px-5 border border-gray-700 transition transition-colors duration-300 ease-in-out rounded-sm;
}

.button-inverted {
  @apply border-teal-500 text-white font-medium;

  background: rgba(26, 153, 146, 0.15);
}

.button-inverted:hover {
  @apply bg-teal-600;
}

.button-lg {
  @apply py-3;
}

.button-primary, a.button-primary {
  @apply border-0 bg-teal-700 text-white font-medium hover:no-underline hover:bg-teal-600;
}

.button-hollow, a.button-hollow {
  @apply bg-transparent text-teal-700 border border-teal-500;
}
.button-primary.button-hollow:hover {
  @apply bg-teal-600 text-white;
}

.button-expand {
  @apply block box-border w-full;
}
.pb-quoteSlider .tns-controls button {
  position: absolute;
  top: 50%;
  bottom: 0;
  width: 70px;
  height: 100%;
  z-index: 30;
  text-indent: -9999px;
  outline: none;

  &:after {
     content: '';
     position: absolute;
     top: 0;
     width: 13.3px;
     height: 25.9px;
     margin-top: -6.65px;

     background-size: 100%;
  }

  @screen md {
    margin-top: -16px;
    width: 19px;
    height: 37px;
  }
}

.pb-quoteSlider button:first-child {
  left: 0;

  @screen md {
    left: 24px;
  }

  &:after {
     left: 12px;
     background-image: url("data:image/svg+xml;charset=utf-8,<svg viewBox='0 0 19 37' xmlns='http://www.w3.org/2000/svg'><path d='M17.5 1.5l-16 17m16 17l-16-17' stroke='%234C4D5D' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='square'/></svg>");
  }
}

.pb-quoteSlider button:last-child {
  right: 0;

  @screen md {
    right: 24px;
  }

  &:after {
     right: 12px;
     background-image: url("data:image/svg+xml;charset=utf-8,<svg viewBox='0 0 19 37' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 1.5l16 17m-16 17l16-17' stroke='%234C4D5D' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='square'/></svg>");
  }
}

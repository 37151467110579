.contact-overlay {
  @apply inset-0 fixed z-40 pointer-events-none invisible;
}

.contact-overlay__underlay {
  @apply inset-0 fixed z-30 pointer-events-none invisible;

  transform: scaleY(0);
  transform-origin: top;
  transition: transform 300ms ease-in;
  transition-delay: 150ms;

  background: rgba(3,68,65,0.95);
}

.contact-overlay--open {
  @apply block h-full pointer-events-auto;
}

.contact-overlay__underlay.contact-overlay--open {
  @apply pointer-events-none;

  transform: scaleY(1);
  transition-delay: 0;
}

/*
** Opening state
*/
.contact-overlay--open form {
  animation: 300ms ease backwards 500ms opacity-in;
}
.contact-overlay--open .contact-overlay__heading,
.contact-overlay--open .contact-overlay__methods > div {
  animation: 300ms ease backwards 375ms slide-up;
}
.contact-overlay--open .contact-overlay__methods > div:nth-child(1) {
  animation-delay: 450ms;
}
.contact-overlay--open .contact-overlay__methods > div:nth-child(2) {
  animation-delay: 450ms;
}


/*
** Closing state
*/
.contact-overlay--close form {
  animation: 300ms ease forwards 0ms opacity-out;
}
.contact-overlay--close .contact-overlay__heading,
.contact-overlay--close .contact-overlay__methods > div {
  overflow: hidden;
  animation: 300ms ease forwards 200ms slide-down;
}
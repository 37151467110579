.prints-grid {
  .grid-sizer, .masonry-item {
    width: 100%;
    padding: 6px 6px 20px 6px;

    @screen md {
      width: 50%;
      padding: 6px;
    }

    @screen lg {
      width: 33.3%;
      padding: 16px;
    }

    @screen xl {
      width: 25%;
      padding: 24px;
    }
  }
}

#prints {
  min-height: 500px;
}
.abstract-block {
  @screen lg {
    min-height: 540px;
    height: 60vh;
  }

  a {
    svg {
      transition: transform 0.3s ease-out;
    }
  }

  a:hover {
    svg {
      transform: translateX(12px);
    }
  }
}

.abstract-block__overlay {
  background: rgb(255,255,255);
  background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 100%);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);

  clip-path: polygon(0 0, 82% 0, 100% 100%, 0% 100%);
}

.abstract-block__overlay-reverse {
  @apply ml-auto;

  background: -moz-linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 100%);
  background: -webkit-linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 100%);
  background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);

  clip-path: polygon(0 0, 100% 0, 100% 100%, 18% 100%);
}
.splash-info {
  min-width: 24rem;

  background: rgba(22,29,29,0.25);
  background: -moz-linear-gradient(45deg, rgba(22,29,29,0.5) 0%, rgba(22,29,29,0) 100%);
  background: -webkit-linear-gradient(45deg, rgba(22,29,29,0.5) 0%, rgba(22,29,29,0) 100%);
  background: linear-gradient(45deg, rgba(22,29,29,0.5) 0%, rgba(22,29,29,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#161d1d",endColorstr="#161d1d",GradientType=1);
}

.splash {
  min-height: 400px;
}

.splash-fade-out {
  @apply relative;

  padding-bottom: 100%;

  @screen sm {
    padding-bottom: 68.75%;
    padding-bottom: min(68.75%, 100vh);
  }
}

.splash-fade-out--compact {
  @screen sm {
    padding-bottom: 50%;
    padding-bottom: min(50%, 100vh);
  }
}

.splash-fade-out:after {
  @apply absolute left-0 right-0 bottom-0;

  height: 100px;
  content: '';

  background-image: linear-gradient(180deg, rgba(255,255,255,0.0) 0%, rgba(255,255,255,0.1) 15%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0.90) 76%, #FFF 100%)
}

/*
 * Splash Menu
 */

.splash-menu {
  @apply grid grid-cols-2 lg:block lg:border-r lg:border-gray-800;
}

.splash-menu li > a {
  @apply block px-6 py-4 lg:py-2 text-gray-800;

  svg {
    @apply hidden opacity-0 transition-opacity duration-300;

    @screen lg {
      @apply inline-block;
    }
  }
}

.splash-menu li > a:hover {
  @apply bg-teal-100;

  svg {
    @apply opacity-100;
  }
}

.splash-menu li > a.splash-menu--active {
  @apply text-teal-600 font-bold bg-teal-50;
}

.splash-menu li:last-child > a {
  @apply border-none;
}
.snipcart-modal__container {
  z-index: 200;
}

#snipcart {
  --color-buttonPrimary: #FFF;
  --bgColor-buttonPrimary: theme('colors.teal.600');
  --bgColor-buttonPrimary-hover: theme('colors.teal.700');
  --color-link: theme('colors.teal.600');
  --color-link-hover: theme('colors.teal.700');
  --borderColor-input-hover: theme('colors.teal.600');
  --borderColor-input-focus: theme('colors.teal.600');

  --color-badge-active: theme('colors.teal.700');
  --bgColor-badge-active: theme('colors.teal.100');

  --bgColor-modal: theme('colors.gray.50');
  --bgColor-alt: theme('colors.gray.50');
}
.mockup-overlay {
  @apply absolute inset-0 flex items-center justify-center;
}

.mockup-frame {
  @apply inline-block flex-grow-0;

  img {
    @apply shadow-sm;
  }
}

.mockup-frame--framed {
  border-style: solid;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

.mockup-frame--print-only {
  box-shadow: 0 2px 3px rgba(0,0,0,0.3);
}

.mockup-frame--black-frame {
  border-color: black;
}

.mockup-frame--white-frame {
  border-color: white;
}

.mockup-frame--wood-frame {
  border-color: #D6BF98;
}

.mockup-mount {
  box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
}

.mockup-mount--white-mount {
  @apply bg-white p-2;
  box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
}
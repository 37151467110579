@import "fullpage.js/dist/fullpage.css";

#fp-nav ul li,
.fp-slidesNav ul li {
  width: 20px;
  height: 22px;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: #FFF;
  @apply shadow;

  height: 6px;
  width: 6px;
  margin: -3px 0 0 -3px;
}

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span{
  height: 20px;
  width: 20px;
  margin: -10px 0 0 -10px;
}

#fullpage .section {
  text-shadow: 0 1px 3px rgba(0,0,0,0.4);
}

#fp-nav ul li .fp-tooltip.fp-right {
  right: 32px;
}

#fp-nav ul li .fp-tooltip {
  top: -1px;
  @apply font-sans font-medium transition-opacity duration-300 ease-out;
  font-size: 1rem;
  text-shadow: 0 1px 3px rgba(0,0,0,0.2);
  max-width: none;
  text-align: right;
}
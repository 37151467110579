@import "../vendor/lightgallery.css";

.grid-sizer, .masonry-item {
  width: 100%;
  padding: 6px;

  @screen md {
    width: 50%;
  }

  @screen lg {
    width: 33.3%;
  }

  @screen xl {
    width: 25%;
  }
}

.grid-sizer--large, .masonry-item--x-2 {
  width: 100%;
  padding: 6px;

  @screen md {
    width: 50%;
  }
}

.masonry-item a {
  @apply block relative;
}

.masonry-overlay {
  @apply absolute inset-0 z-10;
  @apply flex items-center justify-center text-xl font-medium text-white shadow text-center;

  background-color: rgba(33, 194, 187, 0.2);
  transition: clip-path 0.3s ease-out, background-color 0.3s ease-out;
  clip-path: circle(0% at 50% 50%);
}

.masonry-item a:hover .masonry-overlay {
  background-color: rgba(33, 194, 187, 0.6);
  clip-path: circle(100% at 50% 50%);
}

.play-overlay {
  @apply absolute inset-0 z-10;
  @apply flex items-center justify-center text-xl font-medium text-white shadow text-center transition transition-colors duration-300;

  background-color: rgba(40, 52, 52, 0.5);
}

.masonry-item a:hover .play-overlay {
  background-color: rgba(40, 52, 52, 0);
}
.text-content {
  @apply leading-normal text-gray-800;

  > * + *, & li > p + p {
     @apply mt-6;
   }

  strong {
    @apply text-teal-800 font-bold;
  }

  a {
    @apply text-teal-700 font-bold;
  }

  a:hover {
    @apply underline;
  }

  strong a {
    @apply font-bold;
  }

  h1 {
    @apply leading-tight text-3xl lg:text-4xl font-medium text-gray-800 mt-10 mb-2;
  }

  h2 {
    @apply leading-tight text-2xl lg:text-3xl font-medium text-gray-800 mt-10 mb-0;
  }

  h3 {
    @apply leading-tight text-xl lg:text-2xl font-medium text-gray-700 mt-8 -mb-2;
  }

  h4 {
    @apply leading-tight text-lg lg:text-xl font-medium text-gray-700 mt-8 -mb-3;
  }

  > *:first-child {
    @apply mt-0;
  }

  code {
    @apply font-mono text-sm inline bg-gray-500 px-1;
  }

  pre code {
    @apply block bg-black p-4 rounded;
  }

  blockquote {
    @apply italic;

    cite {
      @apply block my-2 uppercase tracking-wide text-sm not-italic font-semibold text-teal-700;
    }
  }

  ul, ol {
    @apply pl-0;
  }

  ul {
    > * {
      display: table-row;
      marker-type: none;
    }

    > *::before {
      display: table-cell;
      padding-right: calc(.5 * 2em); /* 1 */
      content: '›';
      font-size: 22px;
      font-weight: bold;
      @apply text-teal-700;
    }
  }

  & li + li {
      @apply mt-1;
    }

  ul li {
    @apply list-disc;
  }

  ol li {
    @apply list-decimal;
  }

  hr {
    @apply mx-8;

    @screen lg {
      @apply mx-auto max-w-xl;
    }
  }

  figure {
    @apply my-12;
  }
}

.max-h-90-screen {
  max-height: 90vh;
}

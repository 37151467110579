.tile {
  @apply relative bg-gray-100 bg-cover bg-center;
}
.tile:before {
  @apply absolute inset-0;

  content: '';
  background: rgba(33, 194, 187, 0.2);
  transition: clip-path 1s;
  clip-path: circle(0% at 50% 100%);
}

a:hover .tile:before {
  clip-path: circle(150% at 50% 100%);
}

.tile-title {
  @apply absolute left-0 right-0 bottom-0 h-24 flex items-center justify-center;
  @apply text-white font-medium;

  font-size: 1.75rem;
  background-image: linear-gradient(180deg, rgba(22,29,29,0.00) 0%, rgba(22,29,29,0.8) 100%);
}
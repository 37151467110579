.global-menu {
  @apply inset-0 fixed z-40 pointer-events-none invisible;
}

.global-menu__underlay {
  @apply inset-0 fixed z-30 pointer-events-none invisible;

  transform: scaleY(0);
  transform-origin: top;
  transition: transform 300ms ease-in;
  transition-delay: 150ms;

/*  background: rgba(22,29,29,0.95); */

  background: rgba(3,68,65,0.95);
}

.global-menu__underlay.global-menu--open {
  @apply pointer-events-none;

  transform: scaleY(1);
  transition-delay: 0;
}

.global-menu--open {
  @apply block h-full pointer-events-auto;
}

.global-menu__category {
  @apply flex items-center mb-6 md:mb-10 uppercase text-sm font-medium tracking-wider text-teal-100;
}


/*
** Opening state
*/
.global-menu--open .global-menu__category {
  animation: 300ms ease backwards 300ms opacity-in;
}

.global-menu--open .global-menu__items a {
  overflow: hidden;
  animation: 300ms ease backwards 300ms slide-up ;
}
.global-menu--open .global-menu__items a:nth-child(0) {
  animation-delay: 300ms;
}
.global-menu--open .global-menu__items a:nth-child(1) {
  animation-delay: 360ms;
}
.global-menu--open .global-menu__items a:nth-child(2) {
  animation-delay: 420ms;
}
.global-menu--open .global-menu__items a:nth-child(3) {
  animation-delay: 480ms;
}
.global-menu--open .global-menu__items a:nth-child(4) {
  animation-delay: 540ms;
}
.global-menu--open .global-menu__items a:nth-child(5) {
  animation-delay: 600ms;
}
.global-menu--open .global-menu__items a:nth-child(6) {
  animation-delay: 660ms;
}
.global-menu--open .global-menu__items a:nth-child(7) {
  animation-delay: 720ms;
}

/*
** Closing state
*/
.global-menu--close .global-menu__category {
  animation: 300ms ease forwards 300ms opacity-out;
}

.global-menu--close .global-menu__items a {
  overflow: hidden;
  animation: 300ms ease forwards slide-down;
}
.global-menu--close .global-menu__items a:nth-child(7) {
  animation-delay: 0;
}
.global-menu--close .global-menu__items a:nth-child(6) {
  animation-delay: 60ms;
}
.global-menu--close .global-menu__items a:nth-child(5) {
  animation-delay: 120ms;
}
.global-menu--close .global-menu__items a:nth-child(4) {
  animation-delay: 180ms;
}
.global-menu--close .global-menu__items a:nth-child(3) {
  animation-delay: 240ms;
}
.global-menu--close .global-menu__items a:nth-child(2) {
  animation-delay: 300ms;
}
.global-menu--close .global-menu__items a:nth-child(1) {
  animation-delay: 360ms;
}
.global-menu--close .global-menu__items a:nth-child(0) {
  animation-delay: 420ms;
}


@keyframes opacity-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes opacity-out {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}
/** Light color-scheme **/
:root{
  --cc-bg: #fff;
  --cc-text: #2d4156;

  --cc-btn-primary-bg: #2d4156;
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: #1d2e38;
  --cc-btn-secondary-bg: #eaeff2;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #d8e0e6;

  --cc-toggle-bg-off: #919ea6;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #d5dee2;

  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;

  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-bg-hover: #e9eff4;

  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e9edf2;

  --cc-overlay-bg: rgba(4, 6, 8, .85);
  --cc-webkit-scrollbar-bg: #cfd5db;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

/** Dark color-scheme **/
.c_darkmode{
  --cc-bg: #181b1d;
  --cc-text: #d8e5ea;

  --cc-btn-primary-bg: #a6c4dd;
  --cc-btn-primary-text: #000;
  --cc-btn-primary-hover-bg: #c2dff7;
  --cc-btn-secondary-bg: #33383c;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #3e454a;

  --cc-toggle-bg-off: #667481;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #454c54;
  --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-toggle-knob-icon-color: var(--cc-bg);

  --cc-cookie-category-block-bg: #23272a;
  --cc-cookie-category-block-bg-hover: #2b3035;

  --cc-section-border: #292d31;
  --cc-cookie-table-border: #2b3035;

  --cc-webkit-scrollbar-bg: #667481;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

#cc_div *,
#cc_div :before,
#cc_div :after{
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: none;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  font-family: inherit;
  line-height: 1.2;
  font-size: 1em;
  transition: none;
  animation: none;
  margin: 0;
  padding: 0;
  text-transform: none;
  letter-spacing: unset;
  color: inherit;
  background: none;
  border: none;
  box-shadow: none;
  text-decoration: none;
  text-align: left;
}

#cc_div {
  font-size: 16px;
  font-weight: 400;
  font-family: -apple-system, sans-serif;
  color: #2d4156;
  color: var(--cc-text);
}

#cc_div .c_button,
#cc_div .b_title,
#cc_div #cs_title,
#cc_div #cm_title,
#cc_div #cs_blocks table td:before{
  font-weight: bold;
}

#cc_div #cm,
#cc_div #cs_inner,
#cc_div #cs_blocks .cs_block,
#cc_div #cs_cont .block_button .b_title{
  border-radius: .25em;
}

#cc_div input,
#cc_div button,
#cc_div a{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/** Make elements "animatable" **/
.c--anim #cc_div #cm,
.c--anim #cc_div #cs,
.c--anim #cc_div #cs_cont{
  transition: visibility .25s ease, opacity .25s ease, transform .25s ease!important;
}

.c--anim #cc_div .c_button{
  transition: background-color .25s ease!important;
}

.show--consent .c--anim #cc_div #cm,
.show--settings .c--anim #cc_div #cs,
.show--settings .c--anim #cc_div #cs_cont{
  opacity: 1;
  visibility: visible!important;
  transform: scale(1);
}

#cc_div #cm{
  font-family: inherit;
  padding: 1.6em 2.2em 1.825em 2.2em;
  position: fixed;
  z-index: 100;
  background: #fff;
  background: var(--cc-bg);
  max-width: 25em;
  width: 100%;
  bottom: 1.250em;
  right: 1.250em;
  box-shadow: 0 0.625em 1.875em #000000;
  box-shadow: 0 0.625em 1.875em rgba(2, 2, 3, 0.28);
  opacity: 0;
  visibility: hidden;
  transform: scale(.95);
  line-height: initial;
}

#cc_div #cm_title{
  margin: 0 0 0.6em 0;
  font-size: 1.4em;
}

#cc_div #cm_text{
  margin-bottom: 1.625em;
  font-size: 0.9em;
  line-height: 1.45em;
}

#cc_div .c_button{
  color: #40505a;
  color: var(--cc-btn-secondary-text);
  background: #e5ebef;
  background: var(--cc-btn-secondary-bg);
  padding: 1em 1.6em;
  display: inline-block;
  cursor: pointer;
  font-size: 0.85em;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  text-align: center;
  border-radius: 4px;
}

#cc_div #cm .c_button{
  width: 48.5%;
}

#cc_div #cm_btns button + button,
#cc_div #cs_cont button + button,
#cc_div #cs_close_btn{
  float: right;
}

#cc_div #cm .c_link:active,
#cc_div #cm .c_link:hover,
#cc_div #cs_cont button + button:active,
#cc_div #cs_cont button + button:hover,
#cc_div #cs_close_btn:active,
#cc_div #cs_close_btn:hover{
  background: #d8e0e6;
  background: var(--cc-btn-secondary-hover-bg);
}

/**
CookieConsent settings modal
**/
#cc_div #cs_cont{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  background: #070707;
  background: rgba(4, 6, 8, .85);
  background: var(--cc-overlay-bg);
  display: table;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: none!important;
}

#cc_div #cs_blocks{
  outline: none;
}

#cc_div #cs_blocks .title{
  margin-top: 1.4em;
}

#cc_div #cs_blocks .title:first-child{
  margin-top: 0;
}

#cc_div #cs_blocks .b_title{
  font-size: 1.1em;
}

#cc_div #cs_blocks .block_button{
  margin-top: 0;
}

#cc_div #cs_blocks .accordion{
  margin-bottom: 0;
}

#cc_div #cs_blocks .accordion .p{
  margin-top: 0;
  padding: 1em;
}

#cc_div #cs_cont .block_button .b_title{
  display: block;
  font-family: inherit;
  font-size: 1em;
  width: 100%;
  cursor: pointer;
  position: relative;
  padding: 1.4em 6em 1.4em 2.7em;
  background: none;
  transition: background-color .25s ease;
}

#cc_div #cs_cont ._active .block_button .b_title{
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#cc_div #cs_cont .block_button .b_title:active,
#cc_div #cs_cont .block_button .b_title:hover{
  background: #e9eff4;
  background: var(--cc-cookie-category-block-bg-hover);
}

#cc_div #cs_blocks .block_button{
  position: relative;
}

#cc_div #cs_blocks .cs_block{
  padding: 1em;
  margin-bottom: .5em;
  border: 1px solid #f1f3f5;
  border-color: var(--cc-section-border);
  transition: background-color .25s ease;
}

#cc_div #cs_blocks .cs_block:hover{
  background: #f0f4f7;
  background: var(--cc-cookie-category-block-bg);
}

#cc_div #cs_blocks .cs_block:last-child{
  margin-bottom: .5em;
}

#cc_div #cs_blocks .cs_block:first-child{
  transition: none;
  padding: 0;
  margin-top: 0;
  border:none;
  margin-bottom: 2em;
}

#cc_div #cs_blocks .cs_block:first-child:hover{
  background: transparent;
  background: unset;
}

#cc_div #cs_blocks .cs_block.block__expand{
  margin-top: 2em;
  padding: 0;
  border: none;
  background: #f0f4f7;
  background: var(--cc-cookie-category-block-bg);
  transition: none;
}

#cc_div #cs_blocks .cs_block.block__expand + .cs_block{
  margin-top: 2em;
}

#cc_div #cs_blocks .cs_block.block__expand + .cs_block.block__expand{
  margin-top: 0;
}

#cc_div #cs_blocks .cs_block.block__expand:first-child{
  margin-bottom: 1em;
}

#cc_div #cs_blocks .cs_block.block__expand:first-child{
  margin-bottom: .5em;
}

#cc_div #cs_blocks .accordion{
  max-height: 0;
  overflow: hidden;
  padding-top: 0;
}

#cc_div #cs_blocks ._active .accordion{
  max-height: 100%;
}

#cc_div #cs_cont .p{
  font-size: 0.9em;
  line-height: 1.3em;
  margin-top: 1em;
}

#cc_div #cs_cont input{
  -webkit-appearance: none;
}

#cc_div .c_b_toggle input:disabled{
  cursor: not-allowed;
}

#cc_div #cs_valign{
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

#cc_div #cs{
  padding: 0 1.7em;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transform: scale(.96);
  transition: none;
}

#cc_div #cs_inner{
  padding-top: 4.9375em;
  padding-bottom: 4.9375em;
  height: 100%;
  position: relative;
  max-width: 49em;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: rgba(3, 6, 9, .26) 0px 13px 27px -5px;
}

#cc_div #cs_inner,
#cc_div #cs_header,
#cc_div #cs_buttons{
  background: #fff;
  background: var(--cc-bg);
}

#cc_div #cs_blocks{
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  height: 100%;
  padding: 1.5em 2.5em .5em 2.5em;
  display: block;
  width: 100%;
}

#cc_div #cs_buttons{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em 2.5em;
  border-top: 1px solid #f1f3f5;
  border-color: var(--cc-section-border);
  height: 4.9375em;
}

#cc_div .cc-link{
  color: #253b48;
  color: var(--cc-btn-primary-bg);
  border-bottom: 1px solid #253b48;
  border-color: var(--cc-btn-primary-bg);
  display: inline;
  padding-bottom: 0;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

#cc_div #cm_btns button:first-child,
#cc_div #cs_buttons button:first-child{
  color: #fff;
  color: var(--cc-btn-primary-text);
  background: #253b48;
  background: var(--cc-btn-primary-bg);
}

#cc_div .c_b_toggle input:checked ~ .sc_toggle{
  background: #253b48;
  background: var(--cc-toggle-bg-on);
}

#cc_div #cm_btns button:first-child:active,
#cc_div #cm_btns button:first-child:hover,
#cc_div #cs_buttons button:first-child:active,
#cc_div #cs_buttons button:first-child:hover{
  background: #1d2e38;
  background: var(--cc-btn-primary-hover-bg);
}

#cc_div #cs_header{
  position: absolute;
  top: 0;
  width: 100%;
  display: table;
  padding: 1.2em 2.5em;
  vertical-align: middle;
  z-index: 2;
  border-bottom: 1px solid #f1f3f5;
  border-color: var(--cc-section-border);
}

#cc_div #cs_title{
  display: table-cell;
  vertical-align: middle;
  font-size: 1em;
}

#cc_div #cs_close_btn{
  padding: 0;
  width: 1.7em;
  height: 1.7em;
  font-size: 1.6em;
  margin: 0;
  font-weight: initial;
  position: relative;
}

#cc_div #cs_close_btn_cont{
  display: table-cell;
  vertical-align: middle;
}

#cc_div span.toggle_label {
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0;
  width: 0;
  height: 0;
  pointer-events: none;
}

#cc_div #c_policy__text{
  height: 31.250em;
  overflow-y: auto;
  margin-top: 1.250em;
}

#cc_div #cs_cont_inner{
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  height: calc(100% - 2.5em);
  max-height: 37.5em;
}

/** works only on webkit-based browsers **/
#cc_div #cs_blocks::-webkit-scrollbar{
  width: .9em;
  height: 100%;
  background: transparent;
  border-radius: 0 0.250em 0.250em 0;
}

#cc_div #cs_blocks::-webkit-scrollbar-thumb{
  border: 0.25em solid var(--cc-bg);
  background: #cfd5db;
  background: var(--cc-webkit-scrollbar-bg);
  border-radius: 100em;
}

#cc_div #cs_blocks::-webkit-scrollbar-thumb:hover{
  background: #9199a0;
  background: var(--cc-webkit-scrollbar-bg-hover);
}

#cc_div #cs_blocks::-webkit-scrollbar-button {
  width: 10px;
  height: 5px;
}

/** custom checkbox **/
/* The container */
#cc_div .c_b_toggle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: inline-block;
  margin: auto;
  right: 1.2em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
}

/* Hide the browser's default checkbox */
#cc_div .c_b_toggle input {
  position: absolute;
  cursor: pointer;
  display: block;
}

/* Create a custom checkbox */
#cc_div .c_b_toggle .sc_toggle {
  position: absolute;
  border-radius: 4em;
  overflow: hidden;
  background: #919ea6;
  background: var(--cc-toggle-bg-off);
  transition: background-color .25s ease;
  pointer-events: none;
}

#cc_div .c_b_toggle,
#cc_div .c_b_toggle .sc_toggle,
#cc_div .c_b_toggle input{
  width: 3.6em;
  height: 1.6em;
  border-radius: 4em;
}

#cc_div .c_b_toggle .sc_toggle.sc_readonly{
  cursor: not-allowed;
}

#cc_div .c_b_toggle input ~ .sc_toggle.sc_readonly{
  background: #d5dee2;
  background: var(--cc-toggle-bg-readonly);
}

#cc_div .c_b_toggle input ~ .sc_toggle.sc_readonly:after{
  box-shadow: none;
}

/* Style the checkmark/indicator */
#cc_div .c_b_toggle .sc_toggle:after {
  content: "";
  position: absolute;
  left: 0.22em;
  top: 0.2em;
  width: 1.2em;
  height: 1.2em;
  border: none;
  box-sizing: content-box;
  background: #fff;
  background: var(--cc-toggle-knob-bg);
  box-shadow: 0 1px 2px rgb(24 32 35 / 36%);
  transition: transform .3s ease;
  border-radius: 100%;
}

/* Show the checkmark when checked */
#cc_div .c_b_toggle input:checked ~ .sc_toggle:after{
  transform: translateX(1.95em);
}

#cc_div #cs_blocks table,
#cc_div #cs_blocks table th,
#cc_div #cs_blocks table td{
  border: none;
}

#cc_div #cs_blocks table tbody tr{
  transition: background-color .25s ease;
}

#cc_div #cs_blocks table tbody tr:hover{
  background: #e9eff4;
  background: var(--cc-cookie-category-block-bg-hover);
}

#cc_div #cs_blocks table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

#cc_div #cs_blocks table td,
#cc_div #cs_blocks table th {
  padding: 0.8em 0.625em;
  text-align: left;
  vertical-align: top;
  font-size: .8em;
  padding-left: 1.2em;
}

#cc_div #cs_blocks table th {
  font-family: inherit;
  padding: 1.2em 1.2em;
}

#cc_div #cs_blocks thead tr:first-child{
  border-bottom: 1px solid #e9edf2;
  border-color: var(--cc-cookie-table-border);
}

/** BLOCK PAGE SCROLL **/
html.force--consent #cc_div #cm{
  width: 100%;
  width: 100vw;
  max-width: 100%;
  max-width: 100vw;
  left: 0;
  right: 0;
  padding: 2em;
  bottom: 0;
  border-radius: 0;
  position: absolute;
}

html.force--consent #cc_div #cs_cont,
html.force--consent #cc_div #cs{
  width: 100vw;
}

html.force--consent #cc_div{
  width: 100%;
  width: 100vw;
  max-width: 100%;
  max-width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100%;
  min-height: 100vh;
  visibility: hidden;
  transition: none;
  opacity: 0;
  background: #000000;
  background: rgba(20, 21, 22, 0.55);
}

html.force--consent .c--anim  #cc_div{
  transition: opacity .25s ease, visibility .25s ease;
}

html.force--consent #cc_div #cm_inner{
  max-width: 500px;
  margin: 0 auto;
}

html.force--consent.show--consent #cc_div,
html.force--consent.show--settings #cc_div{
  opacity: 1;
  visibility: visible;
}

html.force--consent.show--consent,
html.force--consent.show--settings{
  overflow-y: hidden!important;
}
/** END BLOCK PAGE SCROLL */

/** BEGIN ICONS **/
#cc_div .block_button .b_title::before,
#cc_div ._active .block_button .b_title::before{
  border: solid #2d4156;
  border-color: var(--cc-btn-secondary-text);
  border-width: 0 2px 2px 0;
  padding: .2em;
  display: inline-block;
  position: absolute;
  content: '';
  margin-right: 15px;
  position: absolute;
  transform: translateY(-.2em) rotate(45deg);
  left: 1.2em;
  top: 1.85em;
}

#cc_div ._active .block_button .b_title::before{
  transform: translateY(.05em) rotate(225deg);
}

#cc_div .c_on_icon::before{
  border: solid #ffffff;
  border-color: var(--cc-toggle-knob-icon-color);
  border-width: 0 2px 2px 0;
  padding: .13em;
  display: inline-block;
  padding-bottom: .55em;
  content: '';
  margin: 0 auto;
  transform: rotate(45deg);
  margin-top: .31em;
  margin-left: .1em;
}

#cc_div #cs_close_btn::before,
#cc_div #cs_close_btn::after{
  content: '';
  position: absolute;
  left: .83em;
  top: .55em;
  height: 17px;
  width: 1.5px;
  background: #444d53;
  background: var(--cc-btn-secondary-text);
  transform: rotate(45deg);
  border-radius: 1em;
  margin: 0 auto;
}

#cc_div #cs_close_btn::after{
  transform: rotate(-45deg);
}

#cc_div .c_off_icon,
#cc_div .c_on_icon{
  height: 100%;
  width: 50%;
  position: absolute;
  right: 0;
  display: block;
  text-align: center;
  transition: opacity .25s ease;
}

#cc_div .c_on_icon{
  left: 0;
  opacity: 0;
}

#cc_div .c_off_icon::before,
#cc_div .c_off_icon::after{
  right: .84em;
  top: .4em;
  content: ' ';
  height: .85em;
  width: 0.09375em;
  display: block;
  background: #cdd6dc;
  background: var(--cc-toggle-knob-icon-color);
  margin: 0 auto;
  position: absolute;
  transform-origin: center;
}

#cc_div .c_off_icon::before {
  transform: rotate(45deg);
}
#cc_div .c_off_icon::after {
  transform: rotate(-45deg);
}

#cc_div .c_b_toggle input:checked ~ .sc_toggle .c_on_icon{
  opacity: 1;
}
#cc_div .c_b_toggle input:checked ~ .sc_toggle .c_off_icon{
  opacity: 0;
}
/** END ICONS **/

@media screen and (max-width: 900px){
  #cc_div #cm{
    width: auto;
    max-width: 100%;
    margin: 0;
    padding: 1.875em 10%;
    right: 0;
    bottom: 0;
    border-radius: 0;
    display: block;
  }
}

@media screen and (max-width: 688px) {
  #cc_div #cs{
    border-radius: 0;
    padding: 0;
  }

  #cc_div #cs_cont_inner{
    max-height: 100%;
    height: 100%;
    top: 0;
    transform: none;
  }

  #cc_div .c_b_toggle{
    font-size: 1.25em;
    right: .9em;
  }

  #cc_div #cs_inner{
    margin: 0;
    padding-bottom: 8.1em;
    border-radius: unset;
  }

  #cc_div #cs_blocks{
    padding: 1.3em;
  }

  #cc_div #cs_header{
    padding: 1.2em 1.3em;
  }

  #cc_div #cs_buttons{
    height:8.1em;
    padding: 1em 1.3em;
  }

  /** dynamic table layout **/
  #cc_div #cs_blocks table {
    width: 100%;
  }

  /* Force table to not be like tables anymore */
  #cc_div #cs_blocks table,
  #cc_div #cs_blocks table thead,
  #cc_div #cs_blocks table tbody,
  #cc_div #cs_blocks table th,
  #cc_div #cs_blocks table td,
  #cc_div #cs_blocks table tr,
  #cc_div #cs_cont{
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  #cc_div #cs_blocks table thead tr{
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #cc_div #cs_blocks table tr{
    border-top: 1px solid #e3e7ed;
    border-color: var(--cc-cookie-table-border);
  }

  #cc_div #cs_blocks table td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 40%;
  }

  #cc_div #cs_blocks table td:before {
    position: absolute;
    left: 1em;
    width: 43%;
    padding-right: 0.625em;
    white-space: nowrap;
    content: attr(data-column);
    color: #000;
    color: var(--cc-text);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #cc_div #cm .c_button,
  #cc_div .c_button{
    width: 100%;
    margin-right: 0;
  }

  #cc_div #cm_btns button + button,
  #cc_div #cs_cont button + button{
    margin-top: 0.625em;
    float: unset;
  }
}

/* Begin IE fixes */
#cc_div.ie #cs_valign{
  height: 100%;
  padding-top: 5.62em;
}

#cc_div.ie  #cs{
  max-height: 37.5em;
  position: relative;
  top: 0;
  margin-top: -5.625em;
}

#cc_div.ie #cm{
  border: 1px solid #dee6e9;
}

#cc_div.ie #cs_cont_inner{
  top: 0;
}

#cc_div.ie .c_b_toggle{
  padding-left: 1em;
  margin-bottom: 0.7em;
}

#cc_div.ie .c_b_toggle input:checked ~ .sc_toggle:after{
  left: 1.95em;
}

#cc_div.ie #cs_blocks table{
  overflow: auto;
}

#cc_div.ie .c_b_toggle .sc_toggle{
  display: none;
}

#cc_div.ie .c_b_toggle input{
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.2em;
  height: auto;
}

#cc_div.ie .c_b_toggle .toggle_label{
  filter:alpha(opacity=0);
}

#cc_div.ie #cs_cont .block_button .b_title{
  padding: 1.4em 6em 1.4em 1.4em
}

/** END IE FIXES **/
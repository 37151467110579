.header-underlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 150px;
  max-height: 20vh;
}

.header-underlay, .header-underlay:before {
  @apply pointer-events-none;
  background-image: linear-gradient(180deg, rgba(33,39,39,0.4) 0%, rgba(33,39,39,0.3) 40%, rgba(33,39,39,0.1) 70%, rgba(33,39,39,0) 100%);
}

.header-underlay:before {
  @apply absolute inset-0 opacity-0 transition-opacity;

  transition-duration: 2.5s;
  content: "";
}

.header--stick .header-underlay:before {
  opacity: 1;
}

header.global {
  @apply pointer-events-auto;
}

header.global .logo svg, .header-shadow {
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, .2));
}


.mobile-menu-icon {
  width: 32px;
  height: 22px;
  display: block;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}
.mobile-menu-icon span {
  display: block;
  position: absolute;
  width: 100%;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.mobile-menu-icon span:nth-child(1) {
  top: 0px;
}
.mobile-menu-icon span:nth-child(2), .mobile-menu-icon span:nth-child(3) {
  top: 9px;
}
.mobile-menu-icon span:nth-child(4) {
  top: 18px;
}
.mobile-menu-icon.open span:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}
.mobile-menu-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}
.mobile-menu-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}
.mobile-menu-icon.open span:nth-child(4) {
  top: 6px;
  width: 0%;
  left: 50%;
}
.mobile-menu-icon span {
  height: 3px;
  background: #fff;
  border-radius: 1px;
}
.mobile-menu-icon.open span {
  background: #fff;
}

.global-notification {
  @apply overflow-hidden block w-full text-xs sm:text-sm py-2 px-3 bg-teal-800 bg-opacity-75 text-white text-center pointer-events-auto hover:opacity-80;

  max-height: 64px;
  transition-property: opacity,padding-bottom,padding-top,transform,max-height !important;
}

.header--stick .global-notification {
  opacity: 0;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}